<template>
  <div class="General">
    <!-- header de titulo y acciones -->
    <v-tooltip bottom color="#00cdbc" v-if="showData">
      <template v-slot:activator="{ on }">
        <v-img v-on="on" :src="imagedefaul" :lazy-src="imagedefaul" aspect-ratio="1" class="grey lighten-2 cover-up"
          max-width="100%" max-height="400" style="border-radius: 10px" @click="setuploadimg('cover')">
          <v-row class="fill-height ma-0" align="end" justify="center">
            <v-chip class="mb-5 img-tag"> Cover </v-chip>
          </v-row>
        </v-img>
      </template>
      <span>Cambiar imágen de cover</span>
    </v-tooltip>
    <v-btn :loading="imageDownloadLoading" @click="downloadImage" color="primary" class="mt-6" small text link
      dowload>Descargar plantilla de
      cover</v-btn>
    <headerLayaut v-if="dataInfo" class="mt-4" subtitle="Pantalla general de configuración del evento">
    </headerLayaut>
    <v-container v-if="dataInfoCopy">
      <v-row class="mt-2">
        <v-col cols="12"> </v-col>

        <v-col cols="6">
          <v-select style="border-radius: 10px" :items="countries_" filled :item-value="[`.key`]" rounded dense
            :rules="requerid" required item-text="name" v-model="dataInfoCopy.country" label="Pais"></v-select>
        </v-col>
        <v-col cols="6">
          <v-select style="border-radius: 10px" :items="getCities" filled :item-value="[`.key`]" rounded dense
            :rules="requerid" required item-text="name" v-model="dataInfoCopy.city" label="Ciudad"></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field style="border-radius: 10px" label="Nombre" v-model="dataInfoCopy.name" filled rounded
            dense></v-text-field>
        </v-col>
        <!-- <v-col cols="6">
            <v-text-field
              style="border-radius: 10px"
              label="RTN"
              v-model="dataInfoCopy.tin"
              filled
              rounded
              dense
            ></v-text-field>
          </v-col> -->
        <v-col cols="6" v-if="user.type == 'sudo'">
          <v-text-field style="border-radius: 10px" label="Número de contacto" filled
            v-model="dataInfoCopy.contactPhone" rounded dense></v-text-field>
        </v-col>
        <!-- <v-col cols="6" v-if="user.type == 'sudo'">
            <v-text-field
              style="border-radius: 10px"
              label="Comisión %"
              filled
              v-model="dataInfoCopy.currentPercentage"
              rounded
              dense
            ></v-text-field>
          </v-col> -->

        <v-col cols="6" lg="6">
          <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y
            max-width="290px" min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="dataInfoCopy.dateStart" label="Fecha inicial del evento" persistent-hint
                append-icon="fa-calendar-alt" readonly filled rounded style="border-radius: 10px" dense v-bind="attrs"
                v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="dataInfoCopy.dateStart" no-title :min="hoy" @input="menu1 = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6" lg="6">
          <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
            max-width="290px" min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="dataInfoCopy.dateEnd" label="Fecha final del evento" persistent-hint
                append-icon="fa-calendar-alt" readonly filled rounded style="border-radius: 10px" dense v-bind="attrs"
                v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="dataInfoCopy.dateEnd" no-title :min="hoy" @input="menu2 = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6">
          <!-- <v-text-field
              label="Hora inicial del evento"
              style="border-radius: 10px"
              :rules="requerid"
              filled
              type="time"
              append-icon="fa-solid fa-clock"
              required
              v-model="dataInfoCopy.timeEventStart"
              rounded
            >
            </v-text-field> -->
          <v-select rounded :rules="requerid" label="Hora inicial del evento" style="border-radius: 10px"
            append-icon="fa-solid fa-clock" filled :items="allTime" v-model="dataInfoCopy.timeEventStart" />
        </v-col>
        <v-col cols="6">
          <!-- <v-text-field
              label="Hora final del evento"
              style="border-radius: 10px"
              :rules="requerid"
              filled
              type="time"
              append-icon="fa-solid fa-clock"
              required
              v-model="dataInfoCopy.timeEventEnd"
              rounded
            >
            </v-text-field> -->
          <v-select rounded :rules="requerid" label="Hora final del evento" style="border-radius: 10px"
            append-icon="fa-solid fa-clock" filled :items="allTime" v-model="dataInfoCopy.timeEventEnd" />
        </v-col>

        <v-col cols="6">
          <v-combobox v-model="dataInfoCopy.keywords" :items="dataInfoCopy.keywords" chips clearable
            label="Palabras Claves" multiple dense filled rounded style="border-radius: 10px">
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip v-bind="attrs" :input-value="selected" close small outlined @click="select"
                @click:close="remove(item)">
                <strong>{{ item }}</strong>
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
        <v-col cols="6">
          <v-select :items="typeaudience" filled item-value="value" style="border-radius: 10px" rounded dense
            item-text="item" v-model="dataInfoCopy.ratingAudience" label="Tipo de audiencia"></v-select>
        </v-col>
        <v-col cols="6">
          <v-autocomplete v-model="dataInfoCopy.categories" :items="getCategories" multiple filled
            label="Categoria de evento" chips style="border-radius: 10px" rounded item-value=".key" color="primary"
            clearable item-text="fullName">
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip v-bind="attrs" :input-value="selected" @click="select" close @click:close="removeCategory(item)">
                <strong>{{ item.name }}</strong>
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="6">
          <v-autocomplete v-model="dataInfoCopy.tags" :items="getTags" multiple filled chips label="tags" rounded
            item-value=".key" item-text="name" style="min-height: 45px; border-radius: 10px">
          </v-autocomplete>
        </v-col>

        <v-col cols="6">
          <v-textarea name="input-7-1" style="border-radius: 10px ;" label="Descripción" filled rounded required dense
            auto-grow v-model="dataInfoCopy.description"></v-textarea>
        </v-col>
        <v-col cols="6">
          <v-combobox v-model="dataInfoCopy.instructions" :items="dataInfoCopy.instructions" chips clearable
            label="Lista de instrucciones" multiple width="100%" filled rounded style="border-radius: 10px">
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip v-bind="attrs" :input-value="selected" close small outlined @click="select"
                @click:close="removeInstructions(item)">
                <strong>{{ item }}</strong>
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
        <!-- <v-col cols="6">
            <v-text-field
              label="Precio mínimo del Boleto"
              style="border-radius: 10px"
              :rules="requerid"
              filled
              type="number"
              required
              v-model="dataInfoCopy.basePrice"
              rounded
            ></v-text-field>
          </v-col> -->
        <v-col cols="6">
          <v-text-field style="border-radius: 10px" label="Cantidad máxima de boletos por usuario" filled required
            v-model="dataInfoCopy.maxLimitPerUser" type="number" rounded dense></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field style="border-radius: 10px" label="Tiempo para habilitar escaneo de tickets (Minutos)" filled
            v-model="dataInfoCopy.timeBeforeEvent" type="number" max="1440" min="1" rounded dense></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field style="border-radius: 10px" label="Validar tiempo para después de la función (Minutos)" filled
            v-model.number="dataInfoCopy.functionDateValidation" type="number" max="1440" min="1" rounded
            messages="El tiempo de validación en minutos de la función después de pasada la hora del evento, es decir cuantos minutos estará activo el botón de la función durante ese día."
            dense></v-text-field>
        </v-col>
      </v-row>

      <v-card v-if="extraFees.length > 0 && user.type == 'sudo'" class="pa-4 mb-2" elevation="0">
        <v-card-title>Tarifas</v-card-title>

        <v-row>
          <v-col>
            <v-text-field style="border-radius: 10px" label="Comisión %" filled v-model="dataInfoCopy.currentPercentage"
              rounded @keypress="isNumber($event, dataInfoCopy.currentPercentage)" dense></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field label="Precio minimo que se mostrara en el app" style="border-radius: 10px" :rules="requerid"
              required filled type="number" v-model="dataInfoCopy.basePrice" rounded></v-text-field>
          </v-col>
        </v-row>
        <v-divider class="mb-2"></v-divider>
        <v-card-title>
          <v-row>
            <v-col> Tarifas adicionales </v-col>
          </v-row>
        </v-card-title>
        <v-divider class="mb-2"></v-divider>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row v-for="(fee, index) of extraFees" :key="index" align="center" justify="center">
            <v-col cols="12" md="3">
              <v-autocomplete v-model="fee.extraFeeIsFixed" :items="extraFeeIsFixed"
                placeholder="Seleccione tipo de recargo" required filled label="Tipo de Recargo" rounded
                style="border-radius: 10px" />
            </v-col>

            <v-col cols="12" xs="4" sm="4" md="3">
              <v-text-field style="border-radius: 10px" label="Nombre del recargo" filled required
                :rules="fee.active ? requiredRule : []" v-model="fee.extraFeeName" rounded dense></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field style="border-radius: 10px" label="Valor del recargo" filled
                :rules="fee.active ? requiredRule : []" required @keypress="isNumber($event, fee.extraFeeSum)"
                v-model.number="fee.extraFeeSum" rounded dense></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-row>
                <v-col cols="6">
                  <v-switch dense v-model="fee.active" label="Activa"></v-switch>
                  <v-switch dense v-model="fee.extraFeePerTicket" label="Tarifa por entrada"></v-switch>
                </v-col>

                <v-col cols="6">
                  <v-btn @click="removeFee(index)" small color="red darken-4 ml-2" class="white--text mt-10" fab>
                    <v-icon small> fa fa-trash</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
        <v-btn color="primary" text @click="addFees()">Agregar tarifa</v-btn>
      </v-card>
      <section v-if="user.type == 'sudo'">
        <h1 style="color: #fff" class="mt-12">Configuración Evento VIP</h1>
        <v-divider class="mb-6"> </v-divider>
        <v-row>
          <v-col cols="12" md="6">
            <v-switch label="Es evento Vip" v-model="dataInfoCopy.isVip"></v-switch>
          </v-col>
          <v-col cols="12" md="6" v-if="dataInfoCopy.isVip">
            <v-text-field style="border-radius: 10px" label="Código del Evento" filled required
              v-model="dataInfoCopy.codeEventVip" rounded
              dense></v-text-field>
          </v-col>
        </v-row>
      </section>
      <section v-if="user.type == 'sudo'">
        <h1 style="color: #fff" class="mt-12">Configuración evento Dilo</h1>
        <v-divider class="mb-6"> </v-divider>
        <v-row>

          <v-col cols="12" md="6">
            <v-text-field style="border-radius: 10px" label="ID correlativo evento Dilo" filled required
              v-model="dataInfoCopy.diloEventId" rounded dense></v-text-field>
          </v-col>
        </v-row>
      </section>

      <v-divider> </v-divider>

      <v-row class="mt-2">
        <v-col cols="4">
          <v-switch dense v-model="dataInfoCopy.active" label="Activo"></v-switch>
        </v-col>

        <v-col cols="4" v-if="user.type == 'sudo'">
          <v-switch dense v-model="dataInfoCopy.ochoActive" label="Activo por ocho"></v-switch>
        </v-col>
        <v-col cols="4" v-if="user.type == 'sudo'">
          <v-switch dense v-model="dataInfoCopy.outstanding" label="Destacado"></v-switch>
        </v-col>

        <v-col cols="4" v-if="user.type == 'sudo'">
          <v-switch dense v-model="dataInfoCopy.isComingSoon" label="Próximamente"></v-switch>
        </v-col>

        <v-col cols="4">
          <v-switch dense v-model="dataInfoCopy.isTicketRequired" label="Ticket requerido"></v-switch>
        </v-col>
        <v-col cols="4">
          <v-switch dense v-model="dataInfoCopy.showUpcomingTickets" label="Mostrar próximos tickets"></v-switch>
        </v-col>
        <v-col cols="4">
          <v-switch dense v-model="dataInfoCopy.isFree" label="Es gratis"></v-switch>
        </v-col>
        <v-col cols="4">
          <v-switch dense v-model="dataInfoCopy.variousDates" label="Varias Fechas"></v-switch>
        </v-col>

        <v-col cols="4" v-if="user.type == 'sudo'">
          <v-switch dense v-model="dataInfoCopy.closed" label="Cerrado"></v-switch>
        </v-col>
        <v-col cols="4" v-if="user.type == 'sudo'">
          <v-switch dense v-model="dataInfoCopy.isOpen" label="Esta abierto"></v-switch>
        </v-col>
        <v-col cols="4" v-if="user.type == 'sudo'">
          <v-switch dense v-model="dataInfoCopy.dateValidation" label="Validar horario de tickets"></v-switch>
        </v-col>
        <v-col cols="4" v-if="user.type == 'sudo'">
          <v-switch dense v-model="dataInfoCopy.ticketValidations" label="Validación de ticket"
            :value="true"></v-switch>
        </v-col>
        <v-col cols="4" v-if="user.type == 'sudo'">
          <v-switch dense v-model="dataInfoCopy.isTester" label="Es Tester"></v-switch>
        </v-col>
      </v-row>
      <v-row class="flex-end mb-6">
        <v-col cols="12" class="flex-end">
          <v-btn width="200px" height="50px" style="border-radius: 10px" color="primary" @click="save">
            Guardar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <!-- subir imagen  -->

    <v-dialog v-model="uploadimg" v-if="uploadimg" persistent width="500" style="border-radius: 15px">
      <v-card style="border-radius: 15px">
        <v-card-title class="text-h5 lighten-2">{{ islogo ? "Actualizar logo" : "Actualizar cover" }}
        </v-card-title>
        <v-card-text>
          <v-row class="px-4" style="margin: 0px">
            <v-col cols="12" md="12" sm="12">
              <v-row>
                <v-col cols="12" md="12" sm="12" class="center">
                  <!-- :style="`background-image: url(${FileImage || defaulImage});height: ${islogo?'250px':'200px'};  width:;`" -->
                  <v-img style="border-radius: 10px" height="200" :src="FileImage || imagedefaul" class="mb-4"></v-img>
                  <v-file-input label="Subir imagen" filled v-model="File" @change="onChange" dense style="width: 100%"
                    append-icon="fa fa-plus" prepend-icon="fa fa-image" full-width rounded></v-file-input>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text style="border-radius: 15px" @click="closedModalImage">
            Cancelar
          </v-btn>
          <v-btn style="border-radius: 15px" color="primary" @click="updateImage">
            aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" scrollable width="500px">
      <v-card>
        <v-card-title>
          <v-btn style="right: 10px; top: 10px" icon color="primary" fab small absolute @click="dialog = false">
            <v-icon dark>fa-times</v-icon>
          </v-btn>
          Titulo
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text> </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="actions-btn">
          <v-btn color="primary" text style="border-radius: 10px">cancelar</v-btn>
          <v-btn color="primary" style="border-radius: 10px" @click="save">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapState } from "vuex";
import { db, fb } from "@/firebase";
import headerLayaut from "@/components/header-layaut.vue";
export default {
  name: "General",

  components: {
    headerLayaut,
  },
  data() {
    return {
      valid: true,
      businessDB: null,
      showData: false,
      event: null,
      validFile: true,
      getTags: [],
      File: null,
      imageFile: null,
      requiredRule: [(v) => !!v || "Requerido"],
      FileImage: null,
      uploadimg: false,
      ratingAudience: null,
      typeaudience: [
        {
          item: "Todo publico",
          value: "Todo publico",
        },
        {
          item: "Mayores de 12 años",
          value: "Mayores de 12 años",
        },
        {
          item: "Mayores de edad",
          value: "Mayores de edad",
        },
      ],
      valid: true,
      imagedefaul: require("@/assets/templateImage.svg"),
      hoy: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu1: false,
      menu2: false,
      categories: [],
      countries_: [],
      cities_: [],
      islogo: null,
      dialog: false,
      SectionsVive: [],
      imageDownloadLoading: false,
      modal: false,
      extraFeeIsFixed: [
        { text: "Porcentual", value: false },
        { text: "Fijo", value: true },
      ],
      extraFees: [
        {
          extraFeeIsFixed: "",
          extraFeeName: "",
          extraFeeSum: "",
          active: false,
        },
      ],
      allTime: [
        { text: "07:00 AM", value: "07:00" },
        { text: "07:30 AM", value: "07:30" },
        { text: "08:00 AM", value: "08:00" },
        { text: "08:30 AM", value: "08:30" },
        { text: "09:00 AM", value: "09:00" },
        { text: "09:30 AM", value: "09:30" },
        { text: "10:00 AM", value: "10:00" },
        { text: "10:30 AM", value: "10:30" },
        { text: "11:00 AM", value: "11:00" },
        { text: "11:30 AM", value: "11:30" },
        { text: "12:00 PM", value: "12:00" },
        { text: "12:30 PM", value: "12:30" },
        { text: "01:00 PM", value: "13:00" },
        { text: "01:30 PM", value: "13:30" },
        { text: "02:00 PM", value: "14:00" },
        { text: "02:30 PM", value: "14:30" },
        { text: "03:00 PM", value: "15:00" },
        { text: "03:30 PM", value: "15:30" },
        { text: "04:00 PM", value: "16:00" },
        { text: "04:30 PM", value: "16:30" },
        { text: "05:00 PM", value: "17:00" },
        { text: "05:30 PM", value: "17:30" },
        { text: "06:00 PM", value: "18:00" },
        { text: "06:30 PM", value: "18:30" },
        { text: "07:00 PM", value: "19:00" },
        { text: "07:30 PM", value: "19:30" },
        { text: "08:00 PM", value: "20:00" },
        { text: "08:30 PM", value: "20:30" },
        { text: "09:00 PM", value: "21:00" },
        { text: "09:30 PM", value: "21:30" },
        { text: "10:00 PM", value: "22:00" },
        { text: "10:30 PM", value: "22:30" },
        { text: "11:00 PM", value: "23:00" },
        { text: "11:30 PM", value: "23:30" },
        { text: "12:00 AM", value: "00:00" },
        { text: "12:30 AM", value: "00:30" },
        { text: "01:00 AM", value: "01:00" },
        { text: "01:30 AM", value: "01:30" },
        { text: "02:00 AM", value: "02:00" },
        { text: "02:30 AM", value: "02:30" },
        { text: "03:00 AM", value: "03:00" },
        { text: "03:30 AM", value: "03:30" },
        { text: "04:00 AM", value: "04:00" },
        { text: "04:30 AM", value: "04:30" },
        { text: "05:00 AM", value: "05:00" },
        { text: "05:30 AM", value: "05:30" },
        { text: "06:00 AM", value: "06:00" },
        { text: "06:30 AM", value: "06:30" },
      ],
      dataInfoCopy: {
        contactPhone: "",
        currentPercentage: "",
      },
      requerid: [(v) => !!v || "Campo requerido"],
    };
  },
  computed: {
    ...mapState(["selectedEvent", "user", "business"]),
    dataInfo() {
      return this.selectedEvent;
    },
    getCities() {
      return this.cities_.filter((city) =>
        this.businessDB.cities.includes(city[`.key`])
      );
    },
    getCategories() {
      let categories = this.categories.filter((item) =>
        this.businessDB.oViveCategories.find(
          (e) => e == item[`.key`] && item.city == this.dataInfoCopy.city
        )
      );
      // console.debug(categories.length);
      return categories
        .map((category) => {
          let newCategory = category;
          let city = this.cities_.find(
            (city) => city[`.key`] === category.city
          );
          newCategory.fullName = city ? `${city.name} - ${category.name} ` : "";
          // console.debug("categories", category.name);
          return newCategory;
        })
        .sort(this.compareByFullName);
    },
  },

  watch: {
    "event.cover"(e) {
      this.imagedefaul = e.original;
      this.addNewCover(e);
    },
  },
  methods: {
    ...mapActions([
      "visibleSearch",
      "Alert_",
      "addBusiness",
      "addEventSelection",
      "addNewCover",
      "_searchTerm",
    ]),
    downloadImage() {
      this.imageDownloadLoading = true;

      axios
        .get('https://firebasestorage.googleapis.com/v0/b/el-ocho-dev.appspot.com/o/bannerEventos-01.png?alt=media&token=795d593f-4462-4ab1-98aa-2003a2e9443f', { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "image/png" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `Ejemplo cover ochoVive`;
          link.click();
          URL.revokeObjectURL(link.href);
          this.imageDownloadLoading = false;
        })
        .catch(console.error);
      this.exit = false;
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    updateInfo() {
      // console.debug("sassksm", this.dataInfoCopy[`.key`]);

      let businessUpdate = {
        modifiedAt: new Date(),
        modifiedBy: this.user[`.key`],
        modifiedBy: this.user[`.key`],
        name: this.dataInfoCopy.name,
        // tin: this.dataInfoCopy.tin,
      };

      // modifiedAt: new Date(),
      // modifiedBy: this.user[`.key`],

      // db.collection("events")
      //   .doc(this.dataInfo[`.key`])
      //   .update(obj)
      //   .then(() => {
      //    // console.debug(obj);
      //     this.Alert_({
      //       text: "Cambios Guardados correctamente",
      //       timeout: 2000,
      //       btn_closed: true,
      //       icon: false,
      //       iconType: "mdi-check",
      //       type: "success",
      //     });
      //   });
    },
    addFees() {
      this.extraFees.push({
        extraFeeIsFixed: "",
        extraFeeName: "",
        extraFeeSum: "",
        active: false,
      });
    },
    removeFee(index) {
      this.extraFees = this.extraFees.filter((item, i) => i != index);

      if (this.extraFees.length == 0) {
        this.extraFees = [
          {
            extraFeeIsFixed: "",
            extraFeeName: "",
            extraFeeSum: "",
            active: false,
          },
        ];
      }
    },
    setImage(e) {
      this.imageFile = e.target.files[0];
      // console.debug(this.imageFile);
      this.imageBannerFile = e.target.files[0];

      if (e.target.files[0].type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        this.imgSrcBanner = event.target.result;
      };

      reader.readAsDataURL(this.imageFile);
    },
    async save() {
      // console.debug(this.$refs.form.validate());
      // console.debug(this.dataInfoCopy);
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        const editEvent = this.dataInfoCopy;
        editEvent.modifiedAt = new Date();
        editEvent.modifiedBy = this.user[`.key`];
        editEvent.basePrice = parseFloat(editEvent.basePrice);
        delete editEvent.cover;
        if (this.user.type == "sudo") {
          // editEvent.currentPercentage = this.dataInfoCopy.currentPercentage || "";
          editEvent.ochoActive = this.dataInfoCopy.ochoActive;
          editEvent.extraFees = this.extraFees;
          editEvent.diloEventId = this.dataInfoCopy.diloEventId || [];
          editEvent.contactPhone = this.dataInfoCopy.contactPhone || "";
          editEvent.showUpcomingTickets = this.dataInfoCopy.showUpcomingTickets
            ? true
            : false;
          editEvent.isVip = this.dataInfoCopy.isVip ? true : false;
          editEvent.isTester = this.dataInfoCopy.isTester ? true : false;
        }
        editEvent.ticketValidations = this.dataInfoCopy.ticketValidations || true;
        editEvent.functionDateValidation = this.dataInfoCopy.functionDateValidation || 0;
        // console.debug(editEvent);
        await db
          .collection("events")
          .doc(this.selectedEvent[`.key`])
          .update(editEvent)
          .then(() => {
            // console.debug(editEvent);
            this.updateEventTargetInSecctions();
            this.Alert_({
              text: "Cambios Guardados correctamente",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "success",
            });
          })
          .catch((err) => {
            // console.debug(err);
          });

        this.addEventSelection(this.event);
      } else {
        this.Alert_({
          text: "😬 LLena todos los campos",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-check",
          type: "error",
        });
      }
    },
    remove(item) {
      this.dataInfoCopy.keywords.splice(
        this.dataInfoCopy.keywords.indexOf(item),
        1
      );
    },
    removeInstructions(item) {
      this.dataInfoCopy.instructions.splice(
        this.dataInfoCopy.instructions.indexOf(item),
        1
      );
    },
    updateimageSuccess() {
      this.File = [];
      this.uploadimg = false;
    },
    updateImage() {
      if (this.islogo) {
        this.uploadImg("brand");
      }
      if (!this.islogo) {
        this.uploadImg("banner");
      }
    },
    uploadImg(typeimg) {
      const image = this.File;
      // console.debug("imagen", image.name);
      fb.app()
        .storage(`${process.env.VUE_APP_EL_OCHO_BK}-vive`)
        .ref()
        .child(
          `${this.selectedEvent[`.key`]}/${typeimg}.${image.type.split("/")[1]}`
        )
        .put(image)
        .then(() => {
          this.Alert_({
            text: "La imagen fue Actualizada",
            timeout: 2000,
            btn_closed: true,
            icon: false,
            iconType: "mdi-check",
            type: "success",
          });
          this.updateimageSuccess();
        })
        .catch((error) => {
          //// console.debug(error);
          this.Alert_({
            text: "Ocurrió un error al subir la imágen",
            timeout: 2000,
            btn_closed: true,
            icon: false,
            iconType: "mdi-check",
            type: "error",
          });
        });
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          //console.error("Async: Could not copy text: ", err);
        }
      );
    },
    closedModalImage() {
      this.File = [];

      this.uploadimg = false;
    },
    async updateEventTargetInSecctions() {
      await this.$binding(
        "SectionsVive",
        db
          .collection(`cities/${this.dataInfoCopy.city}/sectionsOvive`)
          .where("active", "==", true)
      );

      for (let i = 0; i < this.SectionsVive.length; i++) {
        const element = this.SectionsVive[i];
        let items = element.target.filter((doc) => {
          return doc.event == this.selectedEvent[`.key`];
        });

        if (items.length > 0) {
          await items.forEach((elementUpdate) => {
            elementUpdate.isComingSoon = this.dataInfoCopy.isComingSoon;
            elementUpdate.closed = this.dataInfoCopy.closed || false;
            elementUpdate.active = this.dataInfoCopy.active;
            elementUpdate.shortName = this.dataInfoCopy.name;
            elementUpdate.basePrice =
              parseInt(this.dataInfoCopy.basePrice) || 0;
            elementUpdate.dateStart = this.dataInfoCopy.dateStart;
            elementUpdate.variousDates =
              this.dataInfoCopy.variousDates || false;
            elementUpdate.timeEventStart =
              this.dataInfoCopy.timeEventStart || 0;
          });
        }
        // console.debug("element", element);
        await db
          .collection(`cities/${this.dataInfoCopy.city}/sectionsOvive`)
          .doc(element[`.key`])
          .update({
            target: element.target,
          })
          .catch((error) => {
            // console.debug(error);
            // throw error;
          });
      }
    },
    setuploadimg(v) {
      //// console.debug(v);
      if (v == "cover") {
        this.uploadimg = true;
        this.islogo = false;
      } else {
        this.islogo = true;
        this.uploadimg = true;
      }
    },
    updateImage() {
      if (this.islogo) {
        this.uploadImg("brand");
      }
      if (!this.islogo) {
        this.uploadImg("banner");
      }
    },

    compareByFullName(a, b) {
      if (a.fullName < b.fullName) return -1;
      if (a.fullName > b.fullName) return 1;
      return 0;
    },
    onChange(e) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const dataURI = e.target.result;
        if (dataURI) {
          this.$emit("load", dataURI);
          this.FileImage = dataURI;
        }
      };
      //// console.debug(this.FileImage);
      reader.readAsDataURL(e);
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.showData = false;
      await this.$binding(
        "event",
        db.collection("events").doc(this.selectedEvent[`.key`])
      ).then(() => {
        this.dataInfoCopy = JSON.parse(JSON.stringify(this.event));
        this.dataInfoCopy.cover
          ? (this.imagedefaul = this.dataInfoCopy.cover.original)
          : require("@/assets/templateImage.svg");
        delete this.dataInfoCopy[`.key`];

        if (this.dataInfoCopy.extraFees && this.event.extraFees.length > 0)
          this.extraFees = Object.assign([], this.event.extraFees);
        else this.dataInfoCopy.extraFees = this.extraFees;
      });
      this.$binding(
        "businessDB",
        db.collection("businesses").doc(this.business[`.key`])
      );
      this._searchTerm("General");
      this.visibleSearch(false);
      this.$binding(
        "countries_",
        db
          .collection("countries")
          .where("active", "==", true)
          .where("deleted", "==", false)
      );
      await this.$binding(
        "cities_",
        db
          .collection("cities")
          .where("active", "==", true)
          .where("deleted", "==", false)
      );
      await this.$binding(
        "categories",
        db.collection("oViveCategories").where("deleted", "==", false)
      );

      await this.$binding(
        "getTags",
        db
          .collection("countries")
          .doc(this.event.country)
          .collection("tagsOVive")
          .where("active", "==", true)
          .where("deleted", "==", false)
      );

      this.showData = true;
    });
  },
};
</script>
<style scoped>
.flex-end {
  width: 100%;
  display: flex;
  justify-content: flex-end !important;
}

.flex-center {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.preview {
  background-size: cover;
  background-position: center;
}
</style>
